import React, { useRef } from "react";
import {
	AppBar,
	Toolbar,
	Typography,
	Button,
	Container,
	Grid,
	Box,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	useMediaQuery,
} from "@mui/material";
import {
	Shield,
	Zap,
	Users,
	BookOpen,
	Code,
	FileCheck,
	Bell,
} from "lucide-react";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import bannerImage from "./assets/bg/banner_secdevaware.png";
import appImg from "./assets/img/tecnologias-devsecaware.png";

import "./App.css";

const theme = createTheme({
	palette: {
		primary: {
			main: "#1976D2", // Definir el color principal
		},
	},
});

function App() {
	// const [isVideoLoaded, setIsVideoLoaded] = useState(false); // Estado para controlar si el video ha cargado

	// const handleVideoLoad = () => {
	// 	setIsVideoLoaded(true); // Marca como cargado cuando el video esté listo
	// };

	//const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const servicesRef = useRef(null);

	const scrollToServices = () => {
		servicesRef.current.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<div>
			{/* Header */}
			<ThemeProvider theme={theme}>
				<AppBar position="fixed" color="primary">
					<Toolbar>
						<IconButton edge="start" color="inherit" aria-label="logo">
							<Shield size={isMobile ? 20 : 24} />{" "}
							{/* Ajustar el tamaño del icono */}
						</IconButton>
						<Typography
							variant={isMobile ? "body1" : "h6"} // Cambiar el tamaño del texto
							sx={{ flexGrow: 1 }}
						>
							DevSecAware
						</Typography>
						<Button
							color="inherit"
							href="#quienes-somos"
							sx={{ fontSize: isMobile ? "0.6rem" : "0.8rem" }} // Tamaño más pequeño en móvil
						>
							Quiénes Somos
						</Button>
						<Button
							color="inherit"
							href="#servicios"
							sx={{ fontSize: isMobile ? "0.6rem" : "0.8rem" }}
						>
							Servicios
						</Button>
						<Button
							color="inherit"
							href="#desarrollo-sistemas"
							sx={{ fontSize: isMobile ? "0.6rem" : "0.8rem" }}
						>
							Desarrollo de Sistemas
						</Button>
						<Button
							color="inherit"
							onClick={() => {
								window.location.href =
									"https://api.whatsapp.com/send?phone=+529612332915&text=Hola%20me%20pueden%20dar%20mas%20informaci%C3%B3n%20de%20sus%20servicios%20de%20ciberseguridad";
							}}
							sx={{ fontSize: isMobile ? "0.6rem" : "0.8rem" }}
						>
							Contacto
						</Button>
					</Toolbar>
				</AppBar>
			</ThemeProvider>

			{/* Main Content */}
			<main>
				{/* Section: Hero */}
				<section
					style={{
						padding: "10px 0",
						backgroundColor: "black",
						color: "white",
						textAlign: "center",
					}}
				>
					<Box
						sx={{
							backgroundImage: `url(${bannerImage})`,
							backgroundSize: "cover",
							backgroundPosition: "center",
							padding: "48px 0",
							textAlign: "center",
							color: "white",
						}}
					>
						<Box
							sx={{
								backgroundColor: "rgba(0, 0, 0, 0.5)", // Fondo negro semi-transparente
								padding: "20px",
								borderRadius: "8px",
								display: "inline-block",
							}}
						>
							<Container>
								<Typography variant="h2" component="h1" gutterBottom>
									Protegiendo su Futuro Digital
								</Typography>
								<Typography variant="subtitle1" color="inherit" gutterBottom>
									DevSecAware: Su socio en seguridad cibernética, ofreciendo
									soluciones integrales para proteger su negocio en el mundo
									digital.
								</Typography>
								<Button
									variant="contained"
									color="primary"
									sx={{ margin: 1 }}
									onClick={scrollToServices}
								>
									Conozca Nuestros Servicios
								</Button>
								<Button
									onClick={() => {
										window.location.href =
											"https://api.whatsapp.com/send?phone=+529612332915&text=Hola%20me%20pueden%20dar%20mas%20informaci%C3%B3n%20de%20sus%20servicios%20de%20ciberseguridad";
									}}
									variant="contained"
									color="primary"
									sx={{ margin: 1 }}
								>
									Contáctenos
								</Button>
							</Container>
						</Box>
					</Box>
				</section>

				{/* Section: Quiénes Somos */}
				<section id="quienes-somos" style={{ padding: "48px 0" }}>
					<Container>
						<Typography variant="h4" component="h2" gutterBottom>
							Quiénes Somos
						</Typography>
						<Typography variant="body1" paragraph>
							En DevSecAware, somos una empresa especializada en la seguridad
							cibernética. Nuestro objetivo es proteger a nuestros clientes de
							las crecientes amenazas digitales a través de soluciones adaptadas
							a sus necesidades.
						</Typography>
						<Typography variant="body1" paragraph>
							Contamos con un equipo de profesionales altamente capacitados en
							la identificación y mitigación de vulnerabilidades, así como en la
							implementación de medidas de seguridad que garantizan la
							integridad de sus sistemas y datos.
						</Typography>
						<Typography variant="body1" paragraph>
							Nuestra misión es ofrecer un servicio integral de ciberseguridad
							que no solo proteja, sino que también eduque y prepare a nuestros
							clientes para el futuro digital.
						</Typography>
					</Container>
				</section>
				<section id="ataques" style={{ padding: "18px 0" }}>
					<Container>
						<Typography variant="h4" component="h2" gutterBottom>
							Ataques en el mundo en tiempo real
						</Typography>
						<Box
							sx={{
								width: "100%",
								height: "0",
								paddingBottom: "56.25%",
								position: "relative",
							}}
						>
							<iframe
								title="ciberataques-mundo"
								src="https://cybermap.kaspersky.com/es/widget/dynamic/dark"
								style={{
									position: "absolute",
									top: 0,
									left: 0,
									width: "100%",
									height: "400px",
								}}
								allowFullScreen
							/>
						</Box>
					</Container>
				</section>

				{/* Section: Servicios */}
				<section
					id="servicios"
					style={{ padding: "48px 0", backgroundColor: "#f5f5f5" }}
					ref={servicesRef}
				>
					<Container>
						<Typography variant="h4" component="h2" gutterBottom>
							Nuestros Servicios
						</Typography>
						<Grid container spacing={4}>
							<Grid item xs={12} md={4}>
								<Card>
									<CardHeader
										avatar={<Zap size={24} />}
										title="Análisis de Vulnerabilidades"
									/>
									<CardContent>
										Realizamos auditorías de seguridad exhaustivas para
										identificar y corregir debilidades en aplicaciones web y
										sistemas de infraestructura.
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} md={4}>
								<Card>
									<CardHeader
										avatar={<Shield size={24} />}
										title="Pentesting"
									/>
									<CardContent>
										Simulamos ataques controlados para evaluar la seguridad de
										su sistema, detectar puntos vulnerables y proporcionar
										soluciones.
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} md={4}>
								<Card>
									<CardHeader
										avatar={<Users size={24} />}
										title="Asesoría en Seguridad Cibernética"
									/>
									<CardContent>
										Ofrecemos consultorías personalizadas para ayudar a su
										empresa a mejorar su postura de seguridad y cumplir con las
										normativas vigentes.
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} md={4}>
								<Card>
									<CardHeader
										avatar={<BookOpen size={24} />}
										title="Capacitación en Ciberseguridad"
									/>
									<CardContent>
										Impartimos cursos y talleres de formación, orientados tanto
										a profesionales de TI como a equipos de trabajo no técnicos.
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} md={4}>
								<Card>
									<CardHeader
										avatar={<Code size={24} />}
										title="Desarrollo Seguro"
									/>
									<CardContent>
										Asistimos a su equipo en la creación de software seguro,
										desde la planificación y codificación hasta la
										implementación de mejores prácticas.
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} md={4}>
								<Card>
									<CardHeader
										avatar={<FileCheck size={24} />}
										title="Auditorías de Compliance"
									/>
									<CardContent>
										Nos aseguramos de que sus procesos y sistemas cumplan con
										las normativas y regulaciones de seguridad, como ISO/IEC
										27001, GDPR, y otras.
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} md={4}>
								<Card>
									<CardHeader
										avatar={<Bell size={24} />}
										title="Monitoreo y Respuesta a Incidentes"
									/>
									<CardContent>
										Proveemos servicios de monitoreo continuo y respuesta rápida
										ante cualquier amenaza cibernética que pueda afectar la
										operación de su negocio.
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</Container>
				</section>

				<section
					id="desarrollo-sistemas"
					style={{ padding: "48px 0", backgroundColor: "#f5f5f5" }}
				>
					<Container>
						<Typography variant="h4" component="h2" gutterBottom>
							Desarrollo de Sistemas
						</Typography>

						{/* Usamos un Grid para dividir el contenido en dos columnas */}
						<Grid container spacing={4}>
							<Grid item xs={12} md={6}>
								<Typography variant="body1" paragraph>
									En DevSecAware, nos especializamos en el desarrollo de
									aplicaciones web y móviles que no solo ofrecen una experiencia
									de usuario de alto nivel, sino que también cumplen con los más
									altos estándares de seguridad.
								</Typography>
								<Typography variant="body1" paragraph>
									Utilizamos la metodología de seguridad OWASP (Open Web
									Application Security Project) para garantizar que nuestras
									aplicaciones sean robustas y seguras desde el diseño hasta la
									implementación. Esto incluye la identificación y mitigación de
									vulnerabilidades comunes, como inyección de código, exposición
									de datos sensibles, autenticación rota, entre otros.
								</Typography>
								<Typography variant="body1" paragraph>
									Nuestro equipo trabaja en estrecha colaboración con nuestros
									clientes para entender sus necesidades y desarrollar
									soluciones personalizadas, asegurando que cada aplicación no
									solo cumpla con las expectativas de funcionalidad, sino
									también con los requerimientos de seguridad necesarios para
									operar en el entorno digital actual.
								</Typography>
								<Typography variant="body1" paragraph>
									Ya sea que necesites una aplicación web escalable o una
									aplicación móvil intuitiva, en DevSecAware ofrecemos un
									desarrollo enfocado en seguridad, fiabilidad y rendimiento.
								</Typography>
							</Grid>

							<Grid item xs={12} md={6}>
								{/* Aquí incluimos la imagen */}
								<Box
									sx={{
										display: "flex",
										justifyContent: "center", // Centrar horizontalmente
										alignItems: "center", // Opcional, para centrar verticalmente si es necesario
									}}
								>
									<img
										src={appImg}
										alt="Desarrollo de aplicaciones"
										style={{
											width: "400px",
											height: "auto",
											borderRadius: "8px",
										}}
									/>
								</Box>
							</Grid>
						</Grid>
					</Container>
				</section>

				{/* Section: Contacto */}
				<section id="contacto" style={{ padding: "48px 0" }}>
					<Container>
						<Typography
							onClick={() => {
								window.location.href =
									"https://api.whatsapp.com/send?phone=+529612332915&text=Hola%20me%20pueden%20dar%20mas%20informaci%C3%B3n%20de%20sus%20servicios%20de%20ciberseguridad";
							}}
							variant="h4"
							component="h2"
							gutterBottom
						>
							Contáctenos
						</Typography>
						<Typography variant="body1" paragraph>
							¿Listo para fortalecer la seguridad de su empresa? Contáctenos hoy
							para una consulta gratuita.
						</Typography>
						<Button
							onClick={() => {
								window.location.href =
									"https://api.whatsapp.com/send?phone=+529612332915&text=Hola%20me%20pueden%20dar%20mas%20informaci%C3%B3n%20de%20sus%20servicios%20de%20ciberseguridad";
							}}
							variant="contained"
							size="large"
						>
							Solicitar Información
						</Button>
					</Container>
				</section>
			</main>

			{/* Footer */}
			<footer
				style={{
					padding: "24px 0",
					borderTop: "1px solid #ddd",
					textAlign: "center",
				}}
			>
				<Container>
					<Typography variant="body2" color="textSecondary">
						© 2024 DevSecAware. Todos los derechos reservados.
					</Typography>
				</Container>
			</footer>
		</div>
	);
}

export default App;
